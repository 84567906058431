
function Team() {
   return (
      <>
         <div className="bg-white border border-gray-200 rounded-lg h-[200px]" />
      </>
   );
 }

 export default Team;
 